import { THEME_COLOR } from 'constants/constants';
import styled from 'styled-components';
import { ButtonTheme } from 'types';
import { ColorBrightnessProps } from 'utils/tools';

export const WrapButtonDisplay = styled.div<{
  screenPosition: string;
  buttonStyle: ButtonTheme;
  widgetThemeColor?: ColorBrightnessProps;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ widgetThemeColor }) => `
    .hide-button {
      box-shadow: 0px 2px 15px 0px #00000026;
      background-color: #fff;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      align-items: center;

      svg {
        margin-top: 4px;
      }

      svg,
      path {
        color: ${widgetThemeColor?.minimizeIconColor};
        fill: ${widgetThemeColor?.minimizeIconColor} !important;
      }
    }
  `}

  .buttonDisplay {
    cursor: pointer;

    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
    background: ${({ buttonStyle }) =>
      THEME_COLOR.BACKGROUND[buttonStyle as ButtonTheme]};

    ${({ screenPosition }) =>
      screenPosition === 'right' && `margin-left: auto;`}

    display: flex;
    justify-content: center;
    align-items: center;

    &.icon-only {
      background: transparent;
      box-shadow: none;
    }
    path {
      fill: var(--text-preview-primary);
    }

    &.type {
      &-icon,
      &-open {
        width: 64px;
        height: 64px;
        background: transparent;
        box-shadow: none;
        border-radius: 50%;
      }

      &-icon_text {
        padding: 10px 14px;
        border-radius: 30px;
        width: max-content;
      }

      &-text {
        padding: 10px 14px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        width: max-content;
      }
    }

    &-empty {
      flex: 1;
      pointer-events: none;
    }

    &-wrapText {
      margin-left: 9px;
      color: ${({ buttonStyle }) =>
        THEME_COLOR.COLOR[buttonStyle as ButtonTheme]};
    }

    &-text {
      color: ${({ buttonStyle }) =>
        THEME_COLOR.COLOR[buttonStyle as ButtonTheme]};
    }
  }
`;
