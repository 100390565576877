import { WrapButtonDisplay } from './styles';
import LottieIcon from '../LottieIcon';
import { HideIcon } from '../SVGIcons';
import { ColorBrightnessProps, getColorBrightness } from 'utils/tools';

export interface IButtonDisplay {
  onClick?: () => void;
  openChatBox?: boolean;
}

export default function ButtonDisplay({
  onClick = () => {},
  openChatBox = false,
}: IButtonDisplay) {
  const widgetThemeColor = getColorBrightness(
    '#eeece8'
  ) as ColorBrightnessProps;

  return (
    <WrapButtonDisplay
      screenPosition="right"
      buttonStyle="light"
      // buttonStyle={(dataCustomization?.buttonTheme || 'light') as ButtonTheme}
      widgetThemeColor={widgetThemeColor}
    >
      <div className="buttonDisplay-empty" />
      <div
        className={`buttonDisplay ${
          openChatBox ? 'type-open' : `type-icon`
        } icon-only`}
        onClick={onClick}
      >
        {openChatBox ? (
          <>
            <div className="hide-button">
              <HideIcon />
            </div>
          </>
        ) : (
          <>
            <LottieIcon size={64} />
          </>
        )}
      </div>
    </WrapButtonDisplay>
  );
}
