import React, { useEffect, useState } from 'react';
import { ChatBoxContainerStyle } from './styles';
import ButtonDisplay from 'components/atoms/ButtonDisplay';
import { LoadingOutlined } from '@ant-design/icons';

// const CHATBOX_HEIGHT_NOCOVERIMAGE = 381;
// const CHATBOX_HEIGHT_COVERIMAGE = 351;

interface ChatBoxContainerProps {
  hasEmbedWebsite?: boolean;
}

const ChatBoxContainer: React.FC<ChatBoxContainerProps> = ({
  hasEmbedWebsite = false,
}) => {
  const [isOpenChatBox, setIsOpenChatBox] = useState<boolean>();
  // const { data } = useQuery(['chat-detail-key', window.devtifyId], () =>
  //   get(`/chat-interfaces/${window.devtifyId}`)
  // );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const readyInit = () => {
        // eslint-disable-next-line
        if ((event as any)?.data?.action === 'ready-init') {
          setLoading(false);
          // initAuth();
        }
        // eslint-disable-next-line
        if ((event as any)?.data?.action === 'reload') {
          window.location.reload();
        }
      };
      window.addEventListener('message', readyInit, false);
    } catch (error) {
      //
    }
  }, []);

  return (
    <ChatBoxContainerStyle
      hasEmbedWebsite={hasEmbedWebsite}
      marginDesktop="20"
      isOpenChatBox={isOpenChatBox}
      process="getStarted"
      buttonDisplay="icon"
      position="right"
      className="ctn-chatBox-entire  position-right"
      // className={`ctn-chatBox-entire ${
      //   dataChatDetail?.position && `position-${dataChatDetail?.position}`
      // }`}
    >
      <div
        id="diaflow-wrapper"
        className={`ctn-chatBox-chatBoxMain ${
          isOpenChatBox ? 'open' : 'hidden'
        }-right`}
      >
        {loading && (
          <div className="loading-wrapper">
            <LoadingOutlined />
          </div>
        )}
        <iframe
          title="Diaflow chat app"
          id="app-builder"
          allow="clipboard-read; clipboard-write; microphone;camera"
          src={`${process.env.REACT_APP_BUILDER_APP_URL}/chat/${window.devtifyId}`}
          // width="375px"
          // height="530px"
        />
      </div>
      <div
        className="ctn-chatBox-startLiveChat position-right"
        // className={`ctn-chatBox-startLiveChat ${
        //   dataChatDetail?.position && `position-${dataChatDetail?.position}`
        // }`}
      >
        {/* {dataChatDetail?.prominenceType === 'button_and_text' &&
          dataChatDetail?.prominenceText &&
          !isHiddenTemporaryTitle && (
            <div className="ctn-chatBox-temporaryTitle">
              <img
                src={dataChatDetail?.companyLogo || noImage}
                alt="company logo"
                width="24px"
                height="24"
                className="ctn-chatBox-temporaryTitle-companyLogo"
              />
              <div className="size-14 black-color fw-400">
                {dataChatDetail.prominenceText}
              </div>
            </div>
          )} */}
        <ButtonDisplay
          onClick={() => {
            setIsOpenChatBox(!isOpenChatBox);
          }}
          openChatBox={isOpenChatBox}
        />
      </div>
    </ChatBoxContainerStyle>
  );
};

export default ChatBoxContainer;
